import "../../styles/shared.scss";
import "./polysites.scss";
import * as React from "react";
import clsx from "classnames";
import { graphql, StaticQuery } from "gatsby";
import {
  TypeIOVSSiteFactoryPageQueryResult,
  getPage,
} from "../../utils/queries/amazeeio_vs_site_factory";
import { PrismicImage, PrismicRichText } from "../../utils/queries/types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";
import { PageTitle } from "../../components/title/title";

const HomepageFeature = ({
  image,
  title1,
  details,
}: {
  image: PrismicImage;
  title1: PrismicRichText | string;
  details: PrismicRichText;
}) => {
  // @ts-ignore
  const titleRendered = title1.hasOwnProperty("raw")
    ? RichTextRender(title1)
    : title1;
  return (
    <div className="col-12 mb-10 col-lg-4 home-page-feature">
      <div className="icon">
        <img src={image.url} alt={image.alt} loading="lazy" />
      </div>
      <div className="title">{RichTextRender(titleRendered)}</div>
      <div className="description">{RichTextRender(details)}</div>
    </div>
  );
};

const IndexPage = ({
  data,
  location,
}: {
  data: TypeIOVSSiteFactoryPageQueryResult;
  location: Location;
}) => {
  const edge = data.allPrismicAmazeeioVsSiteFactory.edges.slice(0, 1).pop();
  if (!edge) return null;
  const result = usePreviewData(getPage(data), "prismicHomepage", true);

  const [tabIndex, setTabIndex] = React.useState(0);
  const tabLength = result.tabs_group.length;
  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="IOVSSiteFactoryPage">
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        {/* Top page section */}
        <section className="main hero-section pt-5 pb-10">
          <div className="container">
            <div className="row mt-10 justify-center">
              <div className="col-12 col-md-12">
                <div className="row title-text">
                  <div className="col-12 col-md-8">
                    <h1>{result.title}</h1>
                    <h2>{result.hero_subtitle}</h2>
                    <h3>{result.hero_subtitle_secondary}</h3>
                    <div className="buttons-group mt-2">
                      <a href="/sign-up">
                        <div className="btn-boldish amazeeio-button rounded-full px-4 py-2 inverted inline-block mt-4">
                          Get Started
                        </div>
                      </a>
                      <a href="/product/">
                        <div className="btn-boldish amazeeio-button rounded-full px-4 py-2 outline inline-block mt-4">
                          View Product
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-10 col-md-4 bg-cover background-item md:bg-center md:w-3/6 md:m-auto bg-no-repeat bg-contain"
                    style={{
                      height: 320,
                      backgroundImage: `url('${result.hero_image.url}')`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="features white">
          <div className="container">
            <div className="upper-text-wrap row justify-content-center">
              <div className="col-12 col-lg-9 text-center">
                <h2 className="section-title">
                  The modern Site Factory alternative
                </h2>
              </div>
              <div className="col-12 col-lg-7 text-center">
                <div className="subtitle">
                  Polysites empower teams to use a single Git repo to deploy
                  100s of sites, with optional per-site repos for custom themes,
                  modules, and deployment features.
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="section-title mb-10">
                  {result.upper_info_blocks_heading}
                </h2>
                <div className="row align-items-start justify-center">
                  {result.features.map((feature, index) => (
                    <HomepageFeature
                      image={feature.image}
                      title1={feature.title1}
                      details={feature.details}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="tabs" className="polysites tabs-section">
          <div className="text-center tabs-wrapper mt-8">
            <div className="tabs-outer tabs">
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <div className="upper-controls">
                  <TabList>
                    {result.tabs_group.map((tab, index) => (
                      <Tab key={index}>
                        {tab.tab_header}
                        <div className="line"></div>
                        <div className="arrow-down"></div>
                      </Tab>
                    ))}
                  </TabList>
                </div>
                <div className="background-gray">
                  <div className="container">
                    <div className="justify-content-center">
                      {result.tabs_group.map((tab, index) => (
                        <TabPanel key={index}>
                          <div className="tab-top-text col-12 col-lg-9 text-center mx-auto">
                            {RichTextRender(tab.tab_heading_intro)}
                          </div>
                          {/*
                          {result.fact_table_row.map((row, index) => (
                            <div>
                              {row.parent_tab === tab.tab_id ? (
                                <div className="row">
                                  <div>{RichTextRender(row.title1)}</div>
                                  <div>{row.value_2}</div>
                                  <div>{row.value_2}</div>
                                </div>
                              ) : null}
                            </div>
                          ))} */}
                          <div className="tab_table_img">
                            <img src={tab.tab_table_img.url} />
                          </div>

                          {tab.tab_quote_image.url && (
                            <section className="quote">
                              <div className="container">
                                <div className="row flex justify-center items-center">
                                  <div className="col-10 text-left">
                                    <div className="description">
                                      {RichTextRender(tab.tab_quote)}
                                    </div>
                                  </div>
                                  {tab.tab_quote_image && (
                                    <div className="col-10 flex justify-start img-wrap">
                                      <img
                                        src={tab.tab_quote_image.url}
                                        alt={tab.tab_quote_image.alt}
                                      />
                                      <div className="quote-text">
                                        <span className="company">
                                          {RichTextRender(
                                            tab.tab_quote_subcopy
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </section>
                          )}

                          {/* If CTA is set  */}
                          {tab.tab_cta_image.url && (
                            <div className="py-12 resource-card-link feature block col-12">
                              <div className="row">
                                <div className="col-12">
                                  <div className="flex resource-card feature cursor-pointer">
                                    <div className="w-full h-full">
                                      <img
                                        src={tab.tab_cta_image.url}
                                        alt={tab.tab_cta_image.alt}
                                        className="resource-image w-full h-full object-cover object-center"
                                        loading="lazy"
                                      />
                                    </div>
                                    <div className="w-full content h-full bg-white">
                                      <div className="content-title text-left">
                                        {RichTextRender(tab.tab_cta_text)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="tab_bottom_html">
                            <div
                              className="table_section"
                              dangerouslySetInnerHTML={{ __html: tab.html }}
                            />
                          </div>

                          <div className="tab_bottom_text">
                            {RichTextRender(tab.tab_bottom_text)}
                          </div>

                          <div className="tab_bottom_img">
                            <img src={tab.tab_bottom_img.url} />
                          </div>

                          <div className="lower-controls">
                            <TabList>
                              {result.tabs_group.map((tab, index) => (
                                <Tab
                                  key={index}
                                  selectedIndex={tabIndex}
                                  className={clsx(
                                    "react-tabs__tab",
                                    tabIndex + 1 === index
                                      ? "block next"
                                      : tabIndex - 1 === index
                                      ? "block prev"
                                      : tabIndex === tabLength - 1 &&
                                        index === 0
                                      ? "block next"
                                      : tabIndex === 0 &&
                                        index === tabLength - 1
                                      ? "block prev"
                                      : "is-hidden"
                                  )}
                                >
                                  {tab.tab_header}
                                  <div className="line"></div>
                                  <div className="arrow-down"></div>
                                </Tab>
                              ))}
                            </TabList>
                          </div>
                        </TabPanel>
                      ))}
                    </div>
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </section>

        <section id="cal-section">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-6 text-left">
                <h2>Get a Free Polysite PaaS Demo</h2>
                <h3>
                  See how Polysite Drupal compares to an Acquia Site Factory
                  experience
                </h3>
                <div className="text-section">
                  <p className="mb-4">
                    What to expect when completing our form:
                  </p>
                  <ul>
                    <li>You’ll book a time with our team straight away</li>
                    <li>We’ll follow up and ask for any relevant details</li>
                    <li>
                      Get ready for a personalized demo tailored to your
                      business and use case
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-lg-4 text-center flex justify-center">
                <img src="https://images.prismic.io/amazeeio/2fdbb870-6caa-4653-8ee0-1b1b3e373da7_Book-a-Demo.png?auto=compress,format" />
              </div>
            </div>
            <iframe
              title={"hubspot"}
              style={{
                width: "100%",
                height: "800px",
              }}
              src={
                "https://go.amazee.io/meetings/martin-schloegl/live-demo?embed=true"
              }
            />
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const IOVSSiteFactoryPageStatic: React.FC<{ location: Location }> = ({
  location,
}) => {
  const query = graphql`
    {
      allPrismicAmazeeioVsSiteFactory {
        edges {
          node {
            data {
              title
              meta_title
              hero_image {
                alt
                url
              }
              hero_text {
                html
                raw
                text
              }
              hero_subtitle
              hero_subtitle_secondary
              page_meta_description
              cta_button_text
              cta_button_text_2
              page_meta_thumbnail {
                alt
                url
              }
              fact_table_row {
                parent_tab
                title1 {
                  html
                  raw
                  text
                }
                value_1
                value_2
              }
              features {
                title1 {
                  html
                  raw
                  text
                }
                image {
                  alt
                  url
                }
                details {
                  html
                  raw
                  text
                }
              }
              tabs_group {
                tab_header
                tab_heading_intro {
                  html
                  raw
                  text
                }
                tab_table_img {
                  alt
                  url
                }
                tab_bottom_img {
                  alt
                  url
                }
                tab_bottom_text {
                  html
                  raw
                  text
                }
                tab_id
                tab_cta_image {
                  alt
                  url
                }
                tab_quote_image {
                  alt
                  url
                }
                tab_cta_text {
                  html
                  raw
                  text
                }
                tab_quote {
                  html
                  raw
                  text
                }
                tab_quote_subcopy {
                  html
                  raw
                  text
                }
                html
              }
            }
          }
        }
      }
    }
  `;
  return (
    <StaticQuery
      render={(result: TypeIOVSSiteFactoryPageQueryResult) => (
        <IndexPage location={location} data={result} />
      )}
      query={`${query}`}
    />
  );
};
export default IOVSSiteFactoryPageStatic;
